@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "EnchantedLand";
  src: local("EnchantedLand"),
    url(https://howdoyouwanttodothis.tube/static/media/Enchanted-Land.18693153.otf) format("truetype");
}

body,
html {
  font-size: 16px;
  line-height: 1.5em;
  font-family: "Lato", sans-serif;
}
h1 {
  font-size: 2.5em;
  font-family: EnchantedLand, serif;
  letter-spacing: 3px;
  font-weight: 100;
  line-height: 1;
}
h2 {
  font-size: 1.6em;
  line-height: 1.2;
}
h3 {
  font-size: 1.2em;
}
.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 0;
  padding: 0 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  z-index: 1;
}

.App-content {
  flex: 1 1;
  z-index: 1;
  width: 100%;
}
@media (min-width: 820px) {
  .App-content {
    width: 800px;
  }
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.video {
  margin: 20px 0px;
}
button {
  padding: 15px;
  font-family: EnchantedLand, serif;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 1.2em;
  text-align: center;
  background: #fff;
  position: relative;
  border: none;
}
.border--top-bottom:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 3px;
  top: -6px;
  left: -7px;
  background: #fff;
  transition: all 0.5s ease;
}

.border--top-bottom:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 3px;
  bottom: -6px;
  right: -7px;
  background: #fff;
  transition: all 0.5s ease;
}

.border--left-right:before {
  content: "";
  position: absolute;
  top: -4px;
  left: -7px;
  width: 3px;
  height: 25px;
  background: #fff;
  transition: all 0.5s ease;
}

.border--left-right:after {
  content: "";
  position: absolute;
  bottom: -4px;
  right: -7px;
  width: 3px;
  height: 25px;
  background: #fff;
  transition: all 0.5s ease;
}

button:hover .border--top-bottom:before,
button:hover .border--top-bottom:after {
  width: calc(100% + 10px);
  transition: all 0.5s ease;
}

button:hover .border--left-right:before,
button:hover .border--left-right:after {
  height: calc(100% + 10px);
  transition: all 0.5s ease;
}

.credits {
  font-size: 12px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.artwork {
  position: absolute;
  width: 40%;
  height: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  z-index: 0;
  display: none;
}
@media only screen and (min-width: 1000px) {
  .artwork {
    display: flex;
  }
}
#bg-left {
  left: 0px;
}
#bg-right {
  right: 0px;
}
.artwork img {
  position: relative;
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: calc(100% - 40px);
}

.campaign-filters {
  margin-bottom: 20px;
}

.campaign-filters__container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.campaign-filters__filter {
  margin: 10px 15px;
}

.campaign-filters__filter input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  right: -1000px;
}
.campaign-filters__filter input[type="checkbox"] + label {
  border: 1px solid white;
  padding: 10px 15px;
  background: white;
  color: #282c34;
  font-weight: 700;
  cursor: pointer;
}
.campaign-filters__filter input[type="checkbox"]:checked + label {
  background: transparent;
  text-decoration: line-through;
  color: grey;
  border-color: grey;
}

@media (min-width: 481px) {
  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 1.8em;
  }
  h3 {
    font-size: 1.5em;
  }
  .App {
    padding: 0;
  }
  button {
    padding: 20px;
    font-size: 1.5em;
  }
  .video {
    margin: 20px;
  }
}
